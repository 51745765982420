import { gql } from '@apollo/client'

export const ADD_SIGN_FILE = gql`
mutation AddSignFile($Name: String!, $Text: String!) {
    addSignFile(Name: $Name, Text: $Text) {
      Id
      Name
      Version
      Text
    }
  }
`

export const REMOVE_SIGN_FILE = gql`
mutation RemoveSignFile($Name: String!) {
    removeSignFile(Name: $Name) {
      Id
      Name
      Version
      Text
    }
  }
`

export const REQUEST_SIGN = gql`
mutation RequestSign($NID: Int!, $FileId: Int!) {
    requestSign(NID: $NID, FileId: $FileId) {
      Id
      NID
      FileId
      Sign
      SignDate
      Fields
    }
  }
`

export const REMOVE_SIGN = gql`
mutation RemoveSign($NID: Int!, $FileId: Int!) {
    removeSign(NID: $NID, FileId: $FileId) {
      Id
      NID
      FileId
      Sign
      SignDate
      Fields
    }
  }
`

export const SIGN_FILE = gql`
mutation SignFile($NID: Int!, $FileId: Int!, $Sign: String!, $Fields: String!) {
    signFile(NID: $NID, FileId: $FileId, Sign: $Sign, Fields: $Fields) {
      Id
      NID
      FileId
      Sign
      SignDate
      Fields
    }
  }
`
