
const editSignFileForm = [
  {
    name: 'Name', placeholder: 'Nombre del archivo...', label: 'Nombre',
    variant: 'standard', fullWidth: true, required: true, xs: 12, sm: 12, disabled: true
  },

  {
    name: 'Text', placeholder: 'Lorem ipsum...', label: 'Texto',
    variant: 'standard', fullWidth: true, required: false, xs: 12, sm: 12,
    multiline: true, rows: 20
  }
]

export default editSignFileForm
