import { Typography } from "@mui/material"
import { FileHeader } from "./FileHeader"

export const FilePreview = ({ title, text, height }) => (
  <div style={{ height: (height) ? height : '80vh' }}>
    <FileHeader />
    <div style={{ marginTop: '1rem' }}>
      <Typography variant='h6'>{title}</Typography>
      <div style={{ whiteSpace: 'pre-wrap' }}>
        <div
          style={{ fontSize: 'small', marginTop: '1rem', textAlign: 'justify' }}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    </div>
  </div>
)
