import { Backdrop, Container, Stack } from "@mui/material"
import { useState } from "react"
import { useParams } from "react-router-dom"
import CenterCircularLoading from "../Components/CenterCircularLoading"
import { useHermanoByNID } from "../Data/Hermanos/custom-hooks"
import { HermanoData } from "../Components/HermanoPage/HermanoData"
import { CuotasData } from "../Components/HermanoPage/CuotasData"
import { FilesData } from "../Components/HermanoPage/FilesData"
import { CenterLoading } from "../Components/CenterLoading"

const HermanoPage = () => {
    const { nid } = useParams()

    const hermano = useHermanoByNID(nid)

    const [loading, setLoading] = useState(false)

    if (hermano.loading) {
        return <div><CenterLoading /></div>
    }

    if (!hermano.data) {
        return <div>Lo sentimos pero no se ha encontrado al Hermano con NID: {nid}</div>
    }

    return (
        <Container>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}>
                <CenterCircularLoading />
            </Backdrop>
            <Stack direction='row' gap='3rem'>
                <Stack direction='column' gap='1rem' width='35%'>
                    <HermanoData NID={nid} hermano={hermano} />
                </Stack>
                <Stack direction='column' gap='1rem' width='65%'>
                    <CuotasData NID={nid} hermano={hermano} setLoading={setLoading} />
                    <FilesData NID={nid} hermano={hermano} setLoading={setLoading} />
                </Stack>
            </Stack >
        </Container >
    )
}

export default HermanoPage