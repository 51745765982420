import { useEffect, useRef, useState } from "react"
import { useReactToPrint } from "react-to-print"
import { FilePreview } from "../Components/Files/FilePreview"
import { Box } from "@mui/material"

export const PrintFileProvider = () => {
    const printRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => printRef.current
    })
    const [title, setTitle] = useState(null)
    const [text, setText] = useState(null)

    useEffect(() => {
        if (title && text) {
            handlePrint()
            setTitle(null)
            setText(null)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, text])

    useEffect(() => {
        const print = (event) => {
            setTitle(event.detail.title)
            setText(event.detail.text)
        }

        window.addEventListener('printFile', print)

        return () => {
            window.removeEventListener('printFile', print)
        }
    })

    return (
        <div style={{ display: 'none' }}>
            <div ref={printRef}>
                {title && text &&
                    <Box margin='2rem'>
                        <FilePreview title={title} text={text} height='calc(100ch - 4rem)' />
                    </Box>
                }
            </div>
        </div>
    )
}