export const usePrint = () => {
  const printCuota = (cuota) => {
    window.dispatchEvent(new CustomEvent('printCuota', { detail: cuota }))
  }

  const printFile = (title, text) => {
    window.dispatchEvent(new CustomEvent('printFile', { detail: { title, text } }))
  }

  return {
    printCuota,
    printFile,
  }
}