import { Box, Button, Stack } from "@mui/material"
import { useState } from "react"
import ReactSignatureCanvas from "react-signature-canvas"

export const Signature = ({ onSign }) => {
  const [sign, setSign] = useState()

  const handleClear = () => {
    sign.clear()
  }

  const handleSign = () => {
    onSign(sign.getTrimmedCanvas().toDataURL('image/png'))
  }

  return (
    <Box>
      <div style={{ width: '90vw', maxWidth: '400px', height: '90vw', maxHeight: '400px' }}>
        <Stack direction='column' gap='1rem' width='100%' height='100%' justifyContent='center'>
          <ReactSignatureCanvas
            canvasProps={{ style: { border: '1px solid black', width: '100%', height: '50%' } }}
            ref={(data) => setSign(data)}
          />
          <Stack direction='row' gap='1rem' justifyContent='end'>
            <Button variant='outlined' onClick={handleClear}>Borrar</Button>
            <Button variant='contained' onClick={handleSign}>Firmar</Button>
          </Stack>
        </Stack>
      </div>
    </Box>
  )
}