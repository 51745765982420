import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"

export const AllCuotaLinesDialog = ({ open, onClose, cuotas }) => {

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='md'
      fullWidth
    >
      <DialogTitle>Todas las cuotas</DialogTitle>
      <DialogContent>
        {cuotas}
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  )
}
