import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, Tooltip, Typography } from "@mui/material"
import { useState } from "react"

export const RequestSignDialog = ({ open, onClose, files, onRequest }) => {

  const [submitting, setSubmitting] = useState(false)

  const handleRequest = async (file) => {
    setSubmitting(true)
    await onRequest(file)
    onClose()
    setSubmitting(false)
  }

  if (!files) return

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='md'
      fullWidth
    >
      <DialogTitle>Solicitar firma</DialogTitle>
      <DialogContent>
        {files.map((item) => (
          <Box key={item.Id}>
            <Stack direction='row' justifyContent='space-between' alignItems='center' style={{ padding: '.25rem 1rem' }}>
              <Stack direction='row' gap='.5rem' maxWidth='60%'>
                <Typography variant='body1'>{item.Id}</Typography>
                <Tooltip title={item.Name}><Typography variant='body1' noWrap flexWrap='nowrap'>{item.Name}</Typography></Tooltip>
                <Typography variant='body1'>v.{item.Version}</Typography>
              </Stack>
              <Button variant='contained' disabled={submitting} onClick={() => handleRequest(item)}>Solicitar firma</Button>
            </Stack>
            <Divider />
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' disabled={submitting} onClick={onClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  )
}
