import { Add, Clear, Close, Edit, Search, Visibility } from "@mui/icons-material"
import { Box, Button, Container, IconButton, InputAdornment, Stack, TextField, Tooltip, Typography } from "@mui/material"
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid'
import { useState } from "react"
import { useSignFiles } from "../Data/SignFiles/custom-hooks"
import { normalizeStr } from "../utils"
import { SignFileDialog } from "../Components/SignFiles/SignFileDialog"
import CreateSignFile from "../Components/SignFiles/CreateSignFile"
import { AcceptCancelDialog } from "../Components/AcceptCancelDialog"
import { FullPageLoading } from "../Components/FullPageLoading"

const SignFilesPage = () => {
  const signFiles = useSignFiles()

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [searchWords, setSearchWords] = useState('')

  const [loading, setLoading] = useState(false)
  const [openCreate, setOpenCreate] = useState(false)
  const [openRemove, setOpenRemove] = useState(false)
  const [openPreview, setOpenPreview] = useState(false)

  const [selectedFile, setSelectedFile] = useState(undefined)

  const columns = [
    { field: 'Id', headerName: 'Id', width: 60 },
    { field: 'Version', headerName: 'Versión', width: 70 },
    { field: 'Name', headerName: 'Nombre', flex: 1 },
    {
      field: 'actions', type: 'actions', align: 'right', flex: 1,
      getActions: ({ row }) => {
        return [
          <Tooltip title='Editar Documento'>
            <GridActionsCellItem
              label='Editar Documento'
              icon={<Edit />}
              onClick={() => {
                setSelectedFile(signFiles.getSignFile(row.Id))
                setOpenCreate(true)
              }}
            />
          </Tooltip>,
          <Tooltip title='Ver Documento'>
            <GridActionsCellItem
              label='Ver Documento'
              icon={<Visibility />}
              onClick={() => {
                setSelectedFile(signFiles.getSignFile(row.Id))
                setOpenPreview(true)
              }}
            />
          </Tooltip>,
          <Tooltip title='Eliminar Documento'>
            <GridActionsCellItem
              label='Eliminar Documento'
              icon={<Close color='error' />}
              onClick={() => {
                setSelectedFile(signFiles.getSignFile(row.Id))
                setOpenRemove(true)
              }}
            />
          </Tooltip>,
        ]
      }
    },
  ]

  const getFilesToShow = () => {
    let filesToShow = [...signFiles.data]

    const normalizedSearch = normalizeStr(searchWords)
    if (normalizedSearch !== '') {
      filesToShow = filesToShow.filter((h) => normalizeStr(h.Name).includes(normalizedSearch))
    }

    return filesToShow
  }

  const handleRemove = async () => {
    if (!selectedFile) return

    setLoading(true)
    await signFiles.remove(selectedFile.Name)
    setLoading(false)
  }

  const handleClose = () => {
    setOpenCreate(false)
    setOpenRemove(false)
    setOpenPreview(false)

    setSelectedFile(undefined)
  }

  return (
    <Box>
      <FullPageLoading open={loading} />
      <Container>
        <Stack direction='column' gap='1rem'>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Typography variant='h4'>Archivos</Typography>
            <Stack direction='row' gap='.5rem' alignItems='center'>
              <Button variant='contained' startIcon={<Add />} onClick={() => setOpenCreate(true)}>Añadir</Button>
            </Stack>
          </Stack>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <div>
              <TextField
                placeholder="Buscar..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <IconButton disabled={!searchWords} style={{ padding: 0 }} onClick={() => setSearchWords('')}>
                      <Clear fontSize="small" />
                    </IconButton>
                  )
                }}
                variant="standard"
                type='text'
                value={searchWords}
                onChange={(e) => setSearchWords(e.target.value)}
              />
            </div>
          </Stack>
          <DataGrid
            style={{
              minHeight: 'calc(100vh - 13rem)',
              maxHeight: 'calc(100vh - 13rem)',
              maxWidth: 'calc(100vw - 7rem)'
            }}
            disableSelectionOnClick
            getRowId={(row) => row.Id}
            loading={signFiles.loading}
            rows={getFilesToShow()}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            pageSize={rowsPerPage}
            pageSizeOptions={[10, 50, 100]}
            onPageSizeChange={(newRowsPerPage) => setRowsPerPage(newRowsPerPage)}
            initialState={{
              sorting: {
                sortModel: [{ field: 'Id', sort: 'desc' }]
              },
              pagination: { paginationModel: { pageSize: 10 } }
            }}
          />
        </Stack>
      </Container>

      <CreateSignFile open={openCreate} onClose={handleClose} onSuccess={async (params) => await signFiles.add(params)} file={selectedFile} />
      <SignFileDialog open={openPreview} onClose={handleClose} text={selectedFile?.Text} title={selectedFile?.Name} />
      <AcceptCancelDialog
        open={openRemove}
        title='Eliminar documento'
        contentText={`Estás seguro de que quieres eliminar el documento ${selectedFile?.Name}. Todas las firmas de cualquier versión de este documento se eliminarán. ESTA ACCIÓN NO SE PUEDE REVERTIR.`}
        acceptLabel='Eliminar'
        onAccept={handleRemove}
        onClose={handleClose}
      />
    </Box>
  )
}

export default SignFilesPage