
export const formatCurrency = (n) => {
    if (!n) return

    const units = Math.floor(n)
    const decimals = Math.floor((n % 1) * 100)

    return `${units}.${formatNumber(decimals)}€`
}

export const formatNumber = (n) => {
    if (n < 10) return '0' + n
    return '' + n
}

export const formatDate = (d) => {
    const parsedDate = new Date(d)
    return `${formatNumber(parsedDate.getDate())}-${formatNumber(parsedDate.getMonth() + 1)}-${parsedDate.getFullYear()}`
}

export const formatLegibleDate = (d) => {
    const parsedDate = new Date(d)
    return `${parsedDate.getDate()} de ${parsedDate.toLocaleDateString('es-ES', { month: 'long' })} del ${parsedDate.getFullYear()}`
}

export const getYear = (d) => {
    const parsedDate = new Date(d)
    return parsedDate.getFullYear()
}

export const possibleYears = (hermano, cuotas) => {
    const birthYear = new Date(hermano.BirthDate).getFullYear()
    const currYear = new Date().getFullYear()

    const allYears = Array.from({ length: currYear - birthYear + 1 }, (_, i) => currYear - i)
    const possibleYears = allYears.filter((y) => !cuotas.find((c) => parseInt(c.CuotaYear) === y))

    return possibleYears
}

export const hasCuotaType = (cuotas, lastCuota) => {
    return !!cuotas.find((c) => c.CuotaType === lastCuota.CuotaType)
}

export const capitalize = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
}

export const getCurrentYears = (bDate, date) => {
    const birthDate = new Date(bDate)
    const today = new Date(date)

    const diff = (today - birthDate) / (365 * 24 * 60 * 60 * 1000)

    return parseInt(diff)
}

export const getToday = () => {
    return new Date().toISOString().substring(0, 10)
}

export const compareDates = (a, b) => {
    const dateA = new Date(a)
    const dateB = new Date(b)

    if (dateA > dateB) return 1
    if (dateA < dateB) return -1
    return 0
}

export const normalizeStr = (value) => {
    return value.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase()
}

export const moveArray = (arr, from, to) => {
    arr.splice(to, 0, arr.splice(from, 1)[0])
    return arr
}