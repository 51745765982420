import { Box, Stack } from "@mui/material"

export const FileHeader = ({ size }) => {

  return (
    <Box>
      <Stack direction='row' gap='2rem' alignItems='center'>
        <Box
          sx={{
            width: {
              xs: '100px',
              md: '125px'
            }
          }}
        >
          <img src='https://hdad.magicdidac.com/escudo.png' width='100%' alt="Escudo de la Hermandad" />
        </Box>
        <Stack direction='column' gap='.5rem'>
          <Box fontSize='.75rem'>
            <div>
              <p><b>Hermandad Ntro. Padre Jesús Nazareno</b></p>
              <p><b>y Ntra. Sra. de la Esperanza</b></p>
            </div>
            <div>
              <p>C/ Rosselló 12-14, Mataró (Barcelona)</p>
              <p>hermandadmataro@gmail.com</p>
            </div>
          </Box>
        </Stack>
      </Stack>
    </Box>
  )
}