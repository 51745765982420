import { Box, Button, Divider, IconButton, Paper, Stack, Typography } from "@mui/material"
import { secondaryColor } from "../../theme"
import { Add } from "@mui/icons-material"
import { CuotaLine } from "../CuotaLine"
import { useCuotasByNID } from "../../Data/Cuotas/custom-hooks"
import { useEmails } from "../../Data/Emails/custom-hooks"
import { useState } from "react"
import { possibleYears } from "../../utils"
import { PayCuota } from "../PayCuota"
import { AcceptCancelDialog } from "../AcceptCancelDialog"
import { EditCuota } from "../EditCuota"
import CenterCircularLoading from "../CenterCircularLoading"
import { useCuotaTypes } from "../../Data/CuotaTypes/custom-hooks"
import { AllCuotaLinesDialog } from "../Cuotas/AllCuotasLinesDialog"
import { usePrint } from "../../Data/Print/custom-hooks"

const CUOTA_LIMIT = 5

export const CuotasData = ({ hermano, setLoading, NID }) => {
  const print = usePrint()
  const email = useEmails()
  const cuotas = useCuotasByNID(NID)
  const cuotaTypes = useCuotaTypes()

  const [openCreate, setOpenCreate] = useState(false)
  const [selectedCuota, setSelectedCuota] = useState(null)
  const [openAllCuotas, setOpenAllCuotas] = useState(false)
  const [openEditCuota, setOpenEditCuota] = useState(false)
  const [openRemoveCuota, setOpenRemoveCuota] = useState(false)

  const handleOpenEditCuota = (selected) => {
    setSelectedCuota(selected)
    setOpenEditCuota(true)
  }

  const handleOpenRemoveCuota = (selected) => {
    setSelectedCuota(selected)
    setOpenRemoveCuota(true)
  }

  const deathOrBaja = () => {
    if (hermano.data.Death) {
      return <Typography color='grey' variant="h6">DIFUNTO</Typography>
    }
    if (hermano.data.Baja) {
      return <Typography color='red' variant="h6">BAJA</Typography>
    }
    return
  }

  const sendEmail = async (cuota) => {
    setLoading(true)
    await email.sendCuota(NID, cuota.CuotaYear)
    setLoading(false)
  }

  const getAllCuotaLines = () => {
    return cuotas.data.map((cuota, index) => <CuotaLine
      key={'cuota-' + index}

      email={hermano.data.Email}
      cuota={cuota}
      onPrint={(c) => print.printCuota(c)}
      onSendEmail={sendEmail}
      onEdit={(c) => handleOpenEditCuota(c)}
      onRemove={(c) => handleOpenRemoveCuota(c)}
    />)
  }

  const getFirstsCuotasLines = () => {
    return getAllCuotaLines().slice(0, CUOTA_LIMIT)
  }

  const handleRemoveCuota = async () => {
    await cuotas.remove(selectedCuota.CuotaYear)
  }

  const closeRemoveCuota = () => {
    setOpenRemoveCuota(false)
    setSelectedCuota(null)
  }

  if (cuotas.loading || cuotaTypes.loading) return <CenterCircularLoading />

  return (
    <Box>
      <Paper>
        <Stack direction='row' alignItems='center' justifyContent='space-between' p='1rem' bgcolor={secondaryColor}>
          <Stack direction='row' gap='.5rem'>
            <Typography variant="h6">Cuotas</Typography>
            {deathOrBaja()}
          </Stack>
          <Stack direction='row' gap='1rem' alignItems='center'>
            {(hermano.data.UpToDate) ?
              <Typography variant="h6" color='green'>AL CORRIENTE</Typography>
              : <Typography variant="h6" color='red'>NO AL CORRIENTE</Typography>
            }
            {possibleYears(hermano.data, cuotas.data).length !== 0 &&
              <IconButton onClick={() => setOpenCreate(true)}><Add /></IconButton>
            }
          </Stack>
        </Stack>
        <Divider />
        {getFirstsCuotasLines()}
      </Paper>
      {cuotas.data.length > CUOTA_LIMIT &&
        <Stack direction='row' justifyContent='end' onClick={() => setOpenAllCuotas(true)}>
          <Button size='small'>Ver todas las Cuotas</Button>
        </Stack>
      }

      <AllCuotaLinesDialog open={openAllCuotas} onClose={() => setOpenAllCuotas(false)} cuotas={getAllCuotaLines()} />

      <PayCuota open={openCreate} onClose={() => setOpenCreate(false)} NID={NID} />
      <EditCuota open={openEditCuota} NID={NID} onClose={() => setOpenEditCuota(false)} cuota={selectedCuota} />
      <AcceptCancelDialog
        open={openRemoveCuota}
        onClose={closeRemoveCuota}
        title='¿Estás seguro?'
        contentText={(selectedCuota) ? `Si aceptas la cuota del año ${selectedCuota.CuotaYear} será eliminada. ESTA ACCIÓN NO SE PUEDE DESHACER` : ``}
        onCancel={closeRemoveCuota}
        onAccept={handleRemoveCuota}
        cancelLabel='Cancelar'
        acceptLabel='Eliminar'
      />
    </ Box>
  )
}
