import { Box, Button, Dialog, DialogContent, Stack, Typography } from "@mui/material"
import Navbar from "./Components/Navbar"
import Sidebar from "./Components/Sidebar"
import { MyRoutes } from "./routes"
import { useUpdater } from './Data/Updater/useUpdater'
import { CenterLoading } from "./Components/CenterLoading"
import { useHermanos } from "./Data/Hermanos/custom-hooks"
import { useSignFiles } from "./Data/SignFiles/custom-hooks"

export const Loader = () => {
  const updater = useUpdater()

  // Load before to improve times
  useHermanos()
  useSignFiles()

  if (updater.loading) {
    return <CenterLoading label='Cargando datos' />
  }

  return (
    <>
      <Navbar />
      <Stack direction="row" gap='1rem' style={{ height: 'calc(100vh - 4rem)' }}>
        <Sidebar />
        <Box marginTop='1rem' width='100%'>
          <MyRoutes />
        </Box>
      </Stack>
      <Dialog
        open={!updater.loading && !updater.isLatest}
        maxWidth='xl'
        fullWidth
      >
        <DialogContent>
          <Stack direction='column' gap='5rem' justifyContent='center' alignItems='center' height='88vh'>
            <Typography variant='h4'>Nueva versión disponible v{updater.lastVersion}</Typography>
            <Button variant='outlined' onClick={() => window.location.reload(false)}>Recargar</Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  )
}