import { useParams } from "react-router-dom"
import { useFileToSign } from "../Data/SignFiles/custom-hooks"
import { Button, Container, Stack, Typography } from "@mui/material"
import { useState } from "react"
import { Form } from "../Components/Form"
import { Signature } from "../Components/SignFiles/Signature"
import { ArrowLeft } from "@mui/icons-material"
import { formatDate } from "../utils"
import { SignFileDialog } from "../Components/SignFiles/SignFileDialog"
import { CenterLoading } from "../Components/CenterLoading"

const SignPage = () => {
  const { NID, FileId } = useParams()
  const fileToSign = useFileToSign(NID, FileId)
  const [loading, setLoading] = useState(false)
  const [fields, setFields] = useState({})
  const [step, setStep] = useState(0)
  const [openFile, setOpenFile] = useState(false)

  const handleSubmit = (values) => {
    setFields(values)
    setStep(step + 1)
  }

  const getOptions = () => {
    const regex = /<([^>]+)>/
    const fields = fileToSign.getFields()
    let options = {}

    for (const field of fields) {
      const match = field.match(regex)


      if (match) {
        const matches = match[1].split(',')
        options = {
          ...options,
          [field]: matches,
          defaults: { ...options.defaults, [field]: fileToSign.getValue(field) ?? matches[0] }
        }
      }
    }

    return options
  }

  const getFields = () => {
    const labels = fileToSign.getFields()

    return labels.map((item) => {
      const field = {
        name: item,
        label: (item.includes('_')) ? item.replace('_', '') : item,
        variant: 'standard',
        fullWidth: true,
        required: (item.includes('_')) ? false : true,
        xs: 12,
        sm: 12,
        defaultValue: fileToSign.getValue(item),
        style: {}
      }

      if (item.includes('<') && item.includes('>')) {
        field.type = 'select'
        field.style.marginTop = '2rem'
      }

      return field
    })
  }

  const handleSign = async (Sign) => {
    setLoading(true)
    await fileToSign.sign(Sign, JSON.stringify(fields))
    setStep(0)
    setLoading(false)
  }

  if (fileToSign.loading || loading) return <CenterLoading label='Cargando...' />

  if (!fileToSign.data || fileToSign.data.length === 0) return (
    <Container>
      <Typography>Lo sentimos pero no se ha solicitado la firma para este documento. Si esto fuese un error, por favor contacta con la Hermandad</Typography>
    </Container>
  )

  switch (step) {
    case 0:
      return (
        <Container>
          <Stack direction='row' justifyContent='center'>
            <Stack direction='column' gap='1rem'>
              <Typography variant='h6'>Solicitud de firma</Typography>
              <Typography><b>Documento:</b> {fileToSign.data.Name}</Typography>
              {fileToSign.getFields().map((item) => <Typography key={item}><b>{(item.includes('_')) ? item.replace('_', '') : item}:</b> {fileToSign.getValue(item)}</Typography>)}
              <Stack direction='row' gap='1rem'>
                <Typography><b>Firma:</b></Typography>
                {fileToSign.data.Sign && <img src={fileToSign.data.Sign} alt='Firma' width='100px' />}
              </Stack>
              <Typography><b>Fecha de firma:</b> {fileToSign.data.Sign && formatDate(fileToSign.data.SignDate)}</Typography>
              <Stack direction='row' gap='1rem' justifyContent='space-between'>
                <Button onClick={() => setOpenFile(true)}>Ver documento</Button>
                {fileToSign.data.Sign ?
                  <Button variant='outlined' onClick={() => setStep(step + 1)}>Firmar de nuevo</Button>
                  :
                  <Button variant='contained' onClick={() => setStep(step + 1)}>Firmar</Button>
                }
              </Stack>
            </Stack>
          </Stack>
          <SignFileDialog open={openFile} onClose={() => setOpenFile(false)} title={fileToSign.data.Name} text={fileToSign.getText()} print />
        </Container>
      )
    case 1:
      return (
        <Container>
          <Stack direction='row' alignItems='center' style={{ cursor: 'pointer' }} onClick={() => setStep(step - 1)}>
            <ArrowLeft />
            <Typography variant='body2'>Volver</Typography>
          </Stack>
          <Form
            title='Solicitud de firma'
            buttonLabel='Siguiente'
            options={getOptions()}
            cb={handleSubmit}
            fields={getFields()}
            focusFirst
          />
          <Stack direction='row' justifyContent='end'>
            <Typography variant='body2' mt='1rem'>Los campos con * son obligatorios</Typography>
          </Stack>
        </Container>
      )
    case 2:
    default:
      return (
        <Container>
          <Stack direction='row' justifyContent='center'>
            <Stack direction='column' alignItems='start'>
              <Stack direction='row' alignItems='center' style={{ cursor: 'pointer' }} onClick={() => setStep(step - 1)}>
                <ArrowLeft />
                <Typography variant='body2'>Volver</Typography>
              </Stack>
              <Signature onSign={handleSign} />
            </Stack>
          </Stack>
        </Container>
      )
  }
}

export default SignPage