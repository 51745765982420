import { gql } from '@apollo/client'

export const GET_SIGN_FILES = gql`
query GetSignFiles {
    getSignFiles {
        Id
        Name
        Version
        Text
    }
}
`

export const GET_SIGNS_BY_NID = gql`
query GetSignsByNID($NID: Int!) {
    getSignsByNID(NID: $NID) {
        Id
        Name
        Version
        Text
        NID
        FileId
        Sign
        SignDate
        Fields
    }
}
`

export const GET_FILE_TO_SIGN = gql`
query GetFileToSign($NID: Int!, $FileId: Int!) {
    getFileToSign(NID: $NID, FileId: $FileId) {
        Id
        Name
        Version
        Text
        NID
        FileId
        Sign
        SignDate
        Fields
    }
}
`
