import { Button, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, Stack, TextField, Typography } from "@mui/material"
import { useHermanoTypes } from '../../Data/HermanoTypes/custom-hooks'
import { useEffect, useState } from "react"
import CenterCircularLoading from '../../Components/CenterCircularLoading'
import { Container, Draggable } from 'react-smooth-dnd'
import { Add, Delete, DragHandle } from '@mui/icons-material'
import { moveArray, normalizeStr } from "../../utils"
import { useNotifications } from "@magicdidac/notifications"
import { AcceptCancelDialog } from '../../Components/AcceptCancelDialog'

export const HermanosSection = () => {
  const notifications = useNotifications()
  const hermanoTypes = useHermanoTypes()
  const [localHermanoTypes, setLocalHermanoTypes] = useState([])
  const [newType, setNewType] = useState('')
  const [loading, setLoading] = useState(false)
  const [deleteType, setDeleteType] = useState(null)

  useEffect(() => {
    if (hermanoTypes.data.length > 0) {
      setLocalHermanoTypes([...hermanoTypes.data])
    }
  }, [hermanoTypes.data])

  const onDrop = ({ removedIndex, addedIndex }) => {
    setLocalHermanoTypes(moveArray([...localHermanoTypes], removedIndex, addedIndex))
  }

  const onSave = async () => {
    setLoading(true)
    try {
      await hermanoTypes.change(localHermanoTypes.map(t => ({ Name: t.Name, Priority: t.Priority })))
    } catch (error) {
      notifications.error('Ha ocurrido un error: ' + error.message)
      setLocalHermanoTypes([...hermanoTypes.data])
    }

    setLoading(false)
  }

  const onAddType = async (event) => {
    event.preventDefault()
    if (newType === '' || loading) return
    if (hermanoTypes.data.findIndex(t => normalizeStr(t.Name) === normalizeStr(newType)) !== -1) {
      notifications.error(`El tipo de Hermano "${newType}" ya existe`)
      return
    }

    setLoading(true)
    try {
      await hermanoTypes.add(newType, hermanoTypes.data.length)
      setNewType('')
    } catch (error) {
      notifications.error('Ha ocurrido un error: ' + error.message)
    }
    setLoading(false)
  }

  const onRemoveType = async () => {
    setLoading(true)

    try {
      await hermanoTypes.remove(deleteType.Name)
    } catch (error) {
      notifications.error('Ha ocurrido un error: ' + error.message)
    }

    setDeleteType(null)
    setLoading(false)
  }

  const isPristine = () => {
    for (let i = 0; i < localHermanoTypes.length; i++) {
      if (!hermanoTypes.data[i]) continue
      if (localHermanoTypes[i].Name !== hermanoTypes.data[i].Name) {
        return false
      }
    }

    return true
  }

  return (
    <>
      <Typography variant='h4' color='gray'>Hermanos</Typography>
      <Divider />
      {hermanoTypes.loading && <CenterCircularLoading />}
      {!hermanoTypes.loading && (
        <Stack direction='column'>
          <Stack direction='row' alignItems='center' gap='1rem'>
            <Typography variant='h6'>Tipos de hermanos</Typography>
          </Stack>
          <List style={{ maxWidth: '300px' }}>
            <Container dragHandleSelector='.drag-handle' lockAxis='y' onDrop={onDrop}>
              {localHermanoTypes.map((hType, index) => (
                <Draggable key={hType.Name}>
                  <Stack direction='row' alignItems='center'>
                    <ListItem className='drag-handle' style={{ cursor: 'move' }}>
                      <ListItemIcon>
                        <DragHandle />
                      </ListItemIcon>
                      <ListItemText primary={hType.Name} />
                    </ListItem>
                    {index !== 0 && <IconButton onClick={() => setDeleteType(hType)}><Delete fontSize='small' color='error' /></IconButton>}
                  </Stack>
                </Draggable>
              ))}
            </Container>
            <ListItem>
              <form onSubmit={onAddType}>
                <ListItemIcon>
                  <Add style={{ cursor: 'pointer' }} onClick={onAddType} />
                </ListItemIcon>
                <TextField
                  disabled={loading}
                  size='small'
                  variant='standard'
                  placeholder="Añadir..."
                  onChange={(event) => setNewType(event.target.value)}
                  value={newType}
                />
              </form>
            </ListItem>
          </List>
          <Button disabled={isPristine()} onClick={onSave}>Guardar</Button>
        </Stack>
      )}
      <AcceptCancelDialog
        open={!!deleteType}
        onAccept={onRemoveType}
        onClose={() => setDeleteType(null)}
        acceptLabel="Eliminar"
        title={`Eliminar el tipo de Hermano "${deleteType?.Name}"`}
        contentText={`Al aceptar eliminarás el tipo de Hermano: "${deleteType?.Name}". Los Hermanos que tengan asignado este tipo pasarán a ser del tipo "${hermanoTypes.data[0]?.Name}". Este cambio no se puede revertir.`}
      />
    </>
  )
}