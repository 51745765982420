import { gql } from '@apollo/client'

export const SEND_CUOTA_EMAIL = gql`
mutation SendCuotaEmail ($NID: String!, $CuotaYear: String!) {
    sendCuotaEmail (NID: $NID, CuotaYear: $CuotaYear)
}
`

export const SEND_SIGN_EMAIL = gql`
mutation SendSignEmail ($NID: Int!, $FileName: String!, $SignUrl: String!) {
    sendSignEmail(NID: $NID, FileName: $FileName, SignUrl: $SignUrl)
}
`