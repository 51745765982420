import { useEffect } from "react"
import { useParams } from "react-router-dom"
import CenterCircularLoading from "../Components/CenterCircularLoading"
import { CuotaPreview } from "../Components/CuotaPreview"
import { useCuotaByNIDAndCuotaYear } from "../Data/Cuotas/custom-hooks"
import { usePrint } from "../Data/Print/custom-hooks"


export const Recibo = () => {
    const { NID, CuotaYear } = useParams()
    const cuota = useCuotaByNIDAndCuotaYear(NID, CuotaYear)
    const print = usePrint()

    useEffect(() => {
        if (cuota.data && !cuota.loading) {
            print.printCuota(cuota.data)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cuota.data])

    if (cuota.loading) {
        return <CenterCircularLoading />
    }

    return (
        <CuotaPreview cuota={cuota.data} />
    )
}