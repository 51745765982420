import { Box, Button, Grid, MenuItem, Select, TextField, Typography, Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";

/*
field: {
    type: 'text' | 'password',
    label: string,
}
*/

export function Form({ title, fields, cb, cancelLabel, closeHandler, buttonLabel, focusFirst, options }) {

    const [submitting, setSubmitting] = useState(false)

    const inputRef = useRef()

    useEffect(() => {
        const timeout = setTimeout(() => {
            inputRef.current?.focus()
        }, 300);
        return () => {
            clearTimeout(timeout)
        }
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (submitting) return
        setSubmitting(true)
        const allData = e.target
        let allValues = {}
        for (let field of fields) {
            allValues[field.name] = allData[field.name].value
        }
        await cb(allValues)
        setSubmitting(false)
    }

    return (
        <Box>
            {title && <Typography variant="h6">{title}</Typography>}
            <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                    {fields.map((field, index) => {
                        let fieldProps = { ...field }
                        if (focusFirst && index === 0) {
                            fieldProps.inputRef = inputRef
                        }

                        return (
                            <Grid key={field.name} item xs={field.xs} sm={field.sm}>
                                {fieldProps.type === 'select' ?
                                    <div style={{ marginTop: (fieldProps.style && fieldProps.style.marginTop) ? fieldProps.style.marginTop : '0' }}>
                                        {fieldProps.label && <Typography fontSize='0.8rem' color='textSecondary' marginBottom='-1.2rem'>{fieldProps.label}</Typography>}
                                        <Select
                                            disabled={submitting}
                                            {...fieldProps}
                                            style={{ ...fieldProps.style, marginTop: '1rem' }}
                                            defaultValue={options && options.defaults && options.defaults[fieldProps.name] ? options.defaults[fieldProps.name] : options[fieldProps.name][0]}
                                        >
                                            {options[fieldProps.name].map((o) => {
                                                return (
                                                    <MenuItem key={o} value={o}>{o}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                    : <TextField disabled={submitting} {...fieldProps} />
                                }
                            </Grid>
                        )
                    })}
                </Grid>
                <Grid item xs={12} style={{ marginTop: '1rem' }}>
                    <Stack direction='row' gap='1rem' justifyContent='center'>
                        {cancelLabel && <Button variant='outlined' disabled={submitting} onClick={() => closeHandler()}>{cancelLabel}</Button>}
                        <Button type='submit' variant='contained' fullWidth={!cancelLabel} disabled={submitting}>{(buttonLabel) ? buttonLabel : 'Submit'}</Button>
                    </Stack>
                </Grid>
            </form>
        </Box>
    )
}