import { Route, Routes } from "react-router-dom"
import { PrivateRoutes } from "./Components/PrivateRoutes"
import HermanoPage from "./Pages/HermanoPage"
import HermanosPage from "./Pages/HermanosPage"
import HomePage from "./Pages/HomePage"
import { Login } from "./Pages/Login"
import { Recibo } from "./Pages/Recibo"
import { Settings } from "./Pages/Settings"
import SignFilesPage from "./Pages/SignFilesPage"
import SignPage from "./Pages/SignPage"

export const MyRoutes = () => {
    return (
        <Routes>
            <Route path='/' element={<Login />} />
            <Route path='/recibo/:NID/:CuotaYear' element={<Recibo />} />
            <Route path='/sign/:NID/:FileId' element={<SignPage />} />
            <Route element={<PrivateRoutes />}>
                <Route path='/home' element={<HomePage />} />
                <Route path='/hermanos' element={<HermanosPage />} />
                <Route path='/hermano/:nid' element={<HermanoPage />} />
                <Route path='/signFiles' element={<SignFilesPage />} />
                <Route path='/settings' element={<Settings />} />
            </Route>
        </Routes>
    )
}