import { useMutation } from '@apollo/client'
import { SEND_CUOTA_EMAIL, SEND_SIGN_EMAIL } from './graphql-mutations'
import { useNotifications } from '@magicdidac/notifications'

export const useEmails = () => {
    const notifications = useNotifications()
    const options = {
        onError: (error) => {
            notifications.error('No se ha podido enviar el email.')
            console.error(error)
        },
        onCompleted: () => {
            notifications.success('El email se ha enviado correctamente.')
        }
    }

    const [sendCuotaEmail] = useMutation(SEND_CUOTA_EMAIL, options)
    const [sendSignEmail] = useMutation(SEND_SIGN_EMAIL, options)

    const sendCuota = async (NID, CuotaYear) => {
        await sendCuotaEmail({ variables: { NID, CuotaYear } })
    }
    const sendSign = async (NID, FileName, SignUrl) => {
        await sendSignEmail({ variables: { NID: Number(NID), FileName, SignUrl } })
    }

    return {
        sendCuota,
        sendSign,
    }
}