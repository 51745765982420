import { useMutation, useQuery } from "@apollo/client";
import { GET_FILE_TO_SIGN, GET_SIGN_FILES, GET_SIGNS_BY_NID } from "./graphql-queries";
import { useNotifications } from "@magicdidac/notifications";
import { ADD_SIGN_FILE, REMOVE_SIGN, REMOVE_SIGN_FILE, REQUEST_SIGN, SIGN_FILE } from "./graphql-mutations";
import { formatLegibleDate } from "../../utils";

const getTextHelper = (file) => {
  if (!file) return

  const text = file.Text

  if (!file.Sign) return text

  const fields = { ...JSON.parse(file.Fields), Sign: `<img src='${file.Sign}' alt='Firma' height='70px' />`, Date: formatLegibleDate(file.SignDate) }

  return text.replace(/\{([^}]+)\}/g, (match, variable) => {
    return fields[variable] ? `<b>${fields[variable]}</b>` : (file.Fields) ? '-' : match;
  })

}

export const useSignFiles = () => {
  const notifications = useNotifications()
  const { data, error, loading } = useQuery(GET_SIGN_FILES)
  const [addSignFile] = useMutation(ADD_SIGN_FILE, {
    refetchQueries: [{ query: GET_SIGN_FILES }],
    onError: (error) => {
      notifications.error('No se ha podido añadir el texto.')
      console.log(error)
    },
    onCompleted: () => {
      notifications.success('Se ha añadido el texto con éxito.')
    }
  })

  const [removeSignFile] = useMutation(REMOVE_SIGN_FILE, {
    refetchQueries: [{ query: GET_SIGN_FILES }],
    onError: (error) => {
      notifications.error('No se ha podido eliminar el documento.')
      console.log(error)
    },
    onCompleted: () => {
      notifications.success('Se ha eliminado el documento con éxito.')
    }
  })

  const add = async (variables) => {
    await addSignFile({ variables })
  }

  const remove = async (Name) => {
    await removeSignFile({ variables: { Name } })
  }

  const getSignFile = (Id) => {
    if (data && data.getSignFiles) {
      return data.getSignFiles.find((item) => item.Id === Id)
    }
  }

  return {
    data: (data && data.getSignFiles) ? data.getSignFiles : [],
    error,
    loading,

    add,
    remove,
    getSignFile
  }
}

export const useSigns = (nid) => {
  const NID = Number(nid)
  const notifications = useNotifications()
  const { data: files } = useQuery(GET_SIGN_FILES)
  const { data, error, loading, refetch } = useQuery(GET_SIGNS_BY_NID, { variables: { NID } })
  const [requestSign] = useMutation(REQUEST_SIGN, {
    refetchQueries: [{ query: GET_SIGNS_BY_NID, variables: { NID } }],
    onError: (error) => {
      notifications.error('No se ha podido pedir la firma.')
      console.error(error)
    },
    onCompleted: () => {
      notifications.success('Se ha solicitado la firma del documento con éxito.')
    }
  })

  const [removeSign] = useMutation(REMOVE_SIGN, {
    refetchQueries: [{ query: GET_SIGNS_BY_NID, variables: { NID } }],
    onError: (error) => {
      notifications.error('No se ha podido eliminar la firma.')
      console.error(error)
    },
    onCompleted: () => {
      notifications.success('Se ha eliminado la firma con éxito.')
    }
  })

  const request = async ({ FileId }) => {
    await requestSign({ variables: { FileId: Number(FileId), NID } })
  }

  const remove = async ({ FileId }) => {
    await removeSign({ variables: { FileId: Number(FileId), NID } })
  }

  const getFilesToRequest = () => {
    if (!files || !files.getSignFiles || !data || !data.getSignsByNID) return []

    const filesToSign = files.getSignFiles.filter((item) => !data.getSignsByNID.find((i) => item.Id === i.FileId))
    return filesToSign.sort((a, b) => a.Id < b.Id ? 1 : -1)
  }

  const getFile = (FileId) => {
    if (!files || !files.getSignFiles || !data || !data.getSignsByNID) return {}

    const file = files.getSignFiles.find((item) => item.Id === FileId)
    const sign = data.getSignsByNID.find((item) => item.FileId === FileId)

    return {
      ...file,
      ...sign
    }
  }

  const getText = (file) => {
    return getTextHelper(file)
  }

  return {
    data: (data && data.getSignsByNID) ? data.getSignsByNID : [],
    error,
    loading,
    refetch,

    request,
    remove,

    getFilesToRequest,
    getFile,
    getText,
  }
}

export const useFileToSign = (nid, fileid) => {
  const NID = Number(nid)
  const FileId = Number(fileid)

  const notifications = useNotifications()
  const { data, error, loading } = useQuery(GET_FILE_TO_SIGN, { variables: { NID, FileId } })
  const [signFile] = useMutation(SIGN_FILE, {
    refetchQueries: [{ query: GET_FILE_TO_SIGN, variables: { NID, FileId } }],
    onError: (error) => {
      notifications.error('No se ha podido firmar el documento.')
      console.log(error)
    },
    onCompleted: () => {
      notifications.success('Se ha firmado el documento con éxito.')
    }
  })

  const sign = async (Sign, Fields) => {
    await signFile({ variables: { NID, FileId, Sign, Fields } })
  }

  const getFields = () => {
    if (!data || !data.getFileToSign) return

    const regex = /\{([^}]+)\}/g
    const fields = []

    let match
    while ((match = regex.exec(data.getFileToSign.Text)) !== null) {
      fields.push(match[1])
    }

    return fields.filter((item) => item !== 'Sign' && item !== 'Date')
  }

  const getText = () => {
    if (!data || !data.getFileToSign) return

    return getTextHelper(data.getFileToSign)
  }

  const getValue = (field) => {
    const obj = JSON.parse(data.getFileToSign.Fields)

    return (obj) ? obj[field] : ''
  }

  return {
    data: (data && data.getFileToSign) ? data.getFileToSign : [],
    error,
    loading,

    sign,
    getFields,
    getText,
    getValue,
  }
}
