import { Close, QrCode2, Send, Visibility } from "@mui/icons-material"
import { Box, Divider, IconButton, Stack, Tooltip, Typography } from "@mui/material"

export const FileLine = ({ file, email, onEmail, onRemove, onQR, onOpenFile }) => {

  return (
    <Box>
      <Stack direction='row' padding='.25rem 1rem' alignItems='center' justifyContent='space-between'>
        <Stack direction='row' maxWidth='50%' alignItems='center'>
          <Typography variant='body2' noWrap>{file.Name}</Typography>
        </Stack>
        <Stack direction='row' gap='1rem' alignItems='center'>
          {file.Sign ?
            <Typography variant='h6' color='green'>FIRMADO</Typography>
            :
            <Typography variant='h6' color='gray'>PENDIENTE</Typography>
          }
          <Stack direction='row' alignItems='center'>
            {file.Sign ?
              <>
                <Tooltip title='Ver documento'><IconButton onClick={() => onOpenFile(file)}><Visibility fontSize="small" /></IconButton></Tooltip>
              </>
              :
              <>
                {email && <Tooltip title='Enviar mail'><IconButton onClick={() => onEmail(file)}><Send fontSize="small" /></IconButton></Tooltip>}
                <Tooltip title='QR'><IconButton onClick={() => onQR(file)}><QrCode2 fontSize="small" /></IconButton></Tooltip>
              </>
            }
            <Tooltip title='Eliminar'><IconButton onClick={() => onRemove(file)}><Close htmlColor='red' fontSize="small" /></IconButton></Tooltip>
          </Stack>
        </Stack>
      </Stack>
      <Divider />
    </Box>
  )
}
