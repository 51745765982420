import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material"
import QRCode from "react-qr-code"

export const QRDialog = ({ open, onClose, text }) => (
  <Dialog
    open={open}
    onClose={onClose}
    maxWidth='sm'
    fullWidth
  >
    <DialogTitle>Escanea para firmar</DialogTitle>
    <DialogContent>
      <Stack justifyContent='center' alignItems='center'>
        <QRCode value={text} />
      </Stack>
    </DialogContent>
    <DialogActions>
      <Button variant='outlined' onClick={onClose}>Cerrar</Button>
    </DialogActions>
  </Dialog>
)
