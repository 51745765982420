import { Box, Divider, IconButton, Paper, Stack, Tooltip, Typography } from "@mui/material"
import { secondaryColor } from "../../theme"
import { Add, Replay } from "@mui/icons-material"
import { useState } from "react"
import { RequestSignDialog } from "../SignFiles/RequestSignDialog"
import { useSigns } from "../../Data/SignFiles/custom-hooks"
import { FileLine } from "../SignFiles/FileLine"
import { AcceptCancelDialog } from "../AcceptCancelDialog"
import { QRDialog } from "../QRDialog"
import { SignFileDialog } from "../SignFiles/SignFileDialog"
import CenterCircularLoading from "../CenterCircularLoading"
import { useEmails } from "../../Data/Emails/custom-hooks"

export const FilesData = ({ NID, hermano, setLoading }) => {
  const signs = useSigns(NID)
  const emails = useEmails()

  const [openDialog, setOpenDialog] = useState(false)
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false)
  const [openQRDialog, setOpenQRDialog] = useState(false)
  const [openFileDialog, setOpenFileDialog] = useState(false)
  const [selectedFile, setSelectedFile] = useState(undefined)

  const handleRequest = async (file) => {
    setLoading(true)
    await signs.request({ FileId: file.Id })
    onEmail(file)
  }

  const onEmail = async (file) => {
    setLoading(true)
    await emails.sendSign(NID, file.Name, `${window.location.origin}/sign/${NID}/${(file.FileId) ? file.FileId : file.Id}`)
    setLoading(false)
  }

  const openRemove = (file) => {
    setSelectedFile(file)
    setOpenRemoveDialog(true)
  }

  const closeRemove = () => {
    setOpenRemoveDialog(false)
    setSelectedFile(undefined)
  }

  const handleRemove = async () => {
    await signs.remove({ FileId: selectedFile.FileId })
    closeRemove()
  }

  const openQR = (file) => {
    setSelectedFile(file)
    setOpenQRDialog(true)
  }

  const closeQR = () => {
    setOpenQRDialog(false)
    setSelectedFile(undefined)
  }

  const openFile = (file) => {
    setSelectedFile(file)
    setOpenFileDialog(true)
  }

  const closeFile = () => {
    setOpenFileDialog(false)
    setSelectedFile(undefined)
  }

  if (signs.loading) return <CenterCircularLoading />

  return (
    <>
      <Paper>
        <Stack direction='column' p='.75rem' bgcolor={secondaryColor}>
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Typography variant="h6">Archivos Firmados</Typography>
            <Stack direction='row'>
              <Tooltip title='Recargar'><IconButton onClick={() => signs.refetch()}><Replay /></IconButton></Tooltip>
              <Tooltip title='Solicitar firma'><IconButton onClick={() => setOpenDialog(true)}><Add /></IconButton></Tooltip>
            </Stack>
          </Stack>
        </Stack>
        <Divider />
        {(!signs.data || signs.data.length === 0) ?
          <Box>
            <Box style={{ padding: '.25rem 1rem' }}>
              <Typography variant="subtitle1">No hay archivos solicitados</Typography>
            </Box>
            <Divider />
          </Box>
          :
          <Box>
            {signs.data.map((item) =>
              <FileLine
                key={item.Id}
                file={signs.getFile(item.FileId)}
                NID={NID}
                email={hermano.data.Email}
                onEmail={onEmail}
                onRemove={openRemove}
                onQR={openQR}
                onOpenFile={openFile}
              />
            )}
          </Box>
        }
      </Paper>

      <RequestSignDialog open={openDialog} files={signs.getFilesToRequest()} onClose={() => setOpenDialog(false)} onRequest={handleRequest} />
      <QRDialog open={openQRDialog} onClose={closeQR} text={`${window.location.origin}/sign/${NID}/${selectedFile?.FileId}`} />
      <SignFileDialog open={openFileDialog} onClose={closeFile} title={selectedFile?.Name} text={signs.getText(selectedFile)} print />
      <AcceptCancelDialog
        open={openRemoveDialog}
        onClose={closeRemove}
        title='¿Estás seguro?'
        contentText={(selectedFile) ? `Si aceptas la firma del documento ${selectedFile.Name} de este hermano será borrada. ESTA ACCIÓN NO SE PUEDE DESHACER` : ``}
        onCancel={closeRemove}
        onAccept={handleRemove}
        cancelLabel='Cancelar'
        acceptLabel='Eliminar'
      />
    </>
  )
}
