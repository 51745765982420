import { Button, Dialog, DialogActions, DialogContent } from "@mui/material"
import { FilePreview } from "../Files/FilePreview"
import { usePrint } from "../../Data/Print/custom-hooks"

export const SignFileDialog = ({ text, title, open, onClose, print }) => {
  const printer = usePrint()

  const getText = () => {
    if (text)
      return text.replace(/{(\w+)}/g, (_, p1) => {
        return `<b>{${p1}}</b>`;
      })
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='sm'
      fullWidth
    >
      <DialogContent>
        <FilePreview title={title} text={getText()} />
      </DialogContent>
      <DialogActions>
        {print && <Button onClick={() => printer.printFile(title, text)}>Imprimir/Guardar</Button>}
        <Button variant='outlined' onClick={onClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  )
}