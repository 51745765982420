import { Drawer } from "@mui/material"
import signFileForm from "../../Forms/SignFileForm"
import { Form } from "../Form"
import editSignFileForm from "../../Forms/EditSignFileForm"

const CreateSignFile = ({ open, file, onClose, onSuccess }) => {
  const handler = async (params) => {
    await onSuccess(params)
    onClose()
  }

  return (
    <Drawer
      PaperProps={{ sx: { width: { xs: "100%", md: 500 }, padding: '1rem' } }}
      anchor="right"
      open={open}
      onClose={onClose}
    >
      {file ?
        <Form
          title='Editar Archivo'
          buttonLabel='Editar'
          fields={editSignFileForm.map((field) => ({ ...field, defaultValue: file[field.name] }))}
          cb={handler}
          focusFirst
        />
        :
        <Form
          title='Añadir Archivo'
          buttonLabel='Añadir'
          fields={signFileForm}
          cb={handler}
          focusFirst
        />
      }
    </Drawer>
  )
}

export default CreateSignFile